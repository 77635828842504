/* eslint-disable */
/* global Excel console */
import {colorIndexMap, defaultFormattingOptions, styles, columnWidth as columnWidths} from "../config/excelConfig";
import {clearSheet, darkenColor, getData, getHeaderData, getMetaData} from "./excelHelper";

function setFormatting(range, parsedValue) {
  console.log(parsedValue?.defaultFormatting?.toUpperCase());
  const defaultFormat = defaultFormattingOptions[parsedValue?.defaultFormatting?.toUpperCase()];
  const customFormat = parsedValue?.customFormatting;
  console.log(defaultFormat);
  if (defaultFormat) {
    if (defaultFormat?.clear === 'contents') range.clear(Excel.ClearApplyTo.contents);
    if (defaultFormat?.clear === 'all') range.clear(Excel.ClearApplyTo.all);
    if (defaultFormat?.clear === 'formats') range.clear(Excel.ClearApplyTo.formats);

    try {
      if (defaultFormat?.format){
        range.format.set(defaultFormat.format);
      }
      if (defaultFormat?.format?.columnWidth) range.format.columnWidth = columnWidths[defaultFormat.format.columnWidth];
      if (defaultFormat?.numberFormat) range.numberFormat = [[defaultFormat.numberFormat]];
    } catch (e) {
      console.log(parsedValue?.defaultFormatting?.toUpperCase());
      console.log(e);
    }

    if (defaultFormat?.color){
      if (styles[defaultFormat?.color] !== null && styles[defaultFormat?.color] !== undefined)
        range.format.fill.color = styles[defaultFormat?.color];
      else
        range.format.fill.color = defaultFormat?.format?.fill?.color;
    }
    if (defaultFormat?.format?.numberFormat) range.format.numberFormat = [[defaultFormat.format.numberFormat]];
  }

  if (customFormat) {
    if (customFormat?.format) {
      let format = customFormat.format;
      if (format?.fill) {
        let fillFormat = format.fill;
        if (fillFormat.color) range.format.fill.color = fillFormat.color;
        if (fillFormat.pattern) range.format.fill.pattern = fillFormat.pattern;
        if (fillFormat.patternColor) range.format.fill.patternColor = fillFormat.patternColor;
        if (fillFormat.patternTintAndShade) range.format.fill.patternTintAndShade = fillFormat.patternTintAndShade;
      }
      if (format?.autoIndent !== undefined) range.format.autoIndent = format.autoIndent;
      if (format?.columnWidth !== undefined) {
        range.format.columnWidth = columnWidths[format.columnWidth];
      }
      if (format?.wrapText !== undefined) range.format.wrapText = format.wrapText;
      if (format?.horizontalAlignment !== undefined) range.format.horizontalAlignment = format.horizontalAlignment;
      if (format?.verticalAlignment !== undefined) range.format.verticalAlignment = format.verticalAlignment;
      if (format?.indentLevel !== undefined) {
        range.format.horizontalAlignment = Excel.HorizontalAlignment.left;
        range.format.indentLevel = format.indentLevel;
      }
      if (format?.rowHeight !== undefined) range.format.rowHeight = format.rowHeight;
      if (format?.shrinkToFit !== undefined) range.format.shrinkToFit = format.shrinkToFit;
      if (format?.textOrientation !== undefined) range.format.textOrientation = format.textOrientation;

      if (format?.font) {
        let fontFormat = format.font;
        let rangeFont = range.format.font;
        if (fontFormat?.bold !== undefined) rangeFont.bold = fontFormat.bold;
        if (fontFormat?.color !== undefined) rangeFont.color = fontFormat.color;
        if (fontFormat?.italic !== undefined) rangeFont.italic = fontFormat.bold;
        if (fontFormat?.name !== undefined) rangeFont.name = fontFormat.name;
        if (fontFormat?.size !== undefined) rangeFont.size = fontFormat.size;
        if (fontFormat?.strikethrough !== undefined) rangeFont.strikethrough = fontFormat.strikethrough;
        if (fontFormat?.subscript !== undefined) rangeFont.subscript = fontFormat.subscript;
        if (fontFormat?.superscript !== undefined) rangeFont.superscript = fontFormat.superscript;
        if (fontFormat?.tintAndShade !== undefined) rangeFont.tintAndShade = fontFormat.tintAndShade;
        if (fontFormat?.underline !== undefined) rangeFont.underline = fontFormat.underline;
      }
    }

    if (customFormat?.numberFormat) range.numberFormat = [[customFormat.numberFormat]];



    // try {
    //   range.format.set(customFormat?.format);
    //   if (customFormat?.format?.columnWidth) {
    //     range.format.columnWidth = columnWidths[customFormat.format.columnWidth];
    //   }
    // } catch (e) {
    //   console.log('err');
    //   console.log(customFormat);
    // }
    // if (customFormat?.indentLevel !== null && customFormat?.indentLevel !== undefined) {
    //   range.format.horizontalAlignment = Excel.HorizontalAlignment.left;
    //   range.format.indentLevel = customFormat.indentLevel;
    // }
    // if (customFormat?.numberFormat !== null && customFormat?.numberFormat !== undefined) range.numberFormat = [[customFormat.numberFormat]];
  }

}

function setMetaData(sheet, metaDataValues, metaDataFormatting) {
  let titleRange = sheet.getRange("B1").getResizedRange(0, metaDataValues[0].length-1);
  titleRange.values = [metaDataValues[0]];
  setFormatting(titleRange, metaDataFormatting[0]);
  for (let i = 1; i < metaDataFormatting.length; i++) {
    let range = sheet.getUsedRange().getLastRow().getCell(0, 0).getResizedRange(1, metaDataValues[0].length-1).getLastRow();
    setFormatting(range, metaDataFormatting[i]);
    range.values = [metaDataValues[i]];
  }
}

function setHeaderValues(headerRange, headerData) {
  try {
    headerRange.values = [headerData];
  } catch (e) {
    console.log(`Couldn't insert header values!`);
    return e;
  }
}

function setHeaderFormatting(headerRange, headerFormatting) {
  const defaultFormat = defaultFormattingOptions[headerFormatting?.defaultFormatting];

  try {
    headerRange.format.set(defaultFormat.format);
  } catch (e) {
    //TODO: Send error message to server
    if (e?.debugInfo?.errorLocation === "RangeFormat.set")
      console.log(e.debugInfo)
  }
  if (defaultFormat?.color) headerRange.format.fill.color = styles[defaultFormat.color];

  const allDefaultRowBorders = [Excel.BorderIndex.edgeTop, Excel.BorderIndex.edgeRight, Excel.BorderIndex.edgeBottom, Excel.BorderIndex.edgeLeft, Excel.BorderIndex.insideHorizontal, Excel.BorderIndex.insideVertical];
  for (const border of allDefaultRowBorders) {
    headerRange.format.borders.getItem(border).style = Excel.BorderLineStyle.continuous;
    headerRange.format.borders.getItem(border).color = styles['InteriorColorIRBorder'];
  }


}
function setHeader(headerRange, headerData, headerFormatting){
  setHeaderValues(headerRange, headerData);
  setHeaderFormatting(headerRange, headerFormatting);
}

function setDefaultRowFormatting(dataRange) {
  const defaultRowFormat = {format:{
      rowHeight: 20,
      verticalAlignment: Excel.VerticalAlignment.center,
      horizontalAlignment: Excel.HorizontalAlignment.center,
      wrapText: true,
      font: {size: 8},
      fill: {color: styles['InteriorColorMainSheet']},
    }};
  try {
    dataRange.format.set(defaultRowFormat.format);
  } catch (e) {
    //TODO: Send error message to server
    if (e?.debugInfo?.errorLocation === "RangeFormat.set")
      console.log(e.debugInfo.innerError)
  }
  const allDefaultRowBorders = [Excel.BorderIndex.edgeTop, Excel.BorderIndex.edgeRight, Excel.BorderIndex.edgeBottom, Excel.BorderIndex.edgeLeft, Excel.BorderIndex.insideHorizontal, Excel.BorderIndex.insideVertical];
  for (const border of allDefaultRowBorders) {
    dataRange.format.borders.getItem(border).style = Excel.BorderLineStyle.dot;
    dataRange.format.borders.getItem(border).color = styles['InteriorColorIRBorder'];
  }
}


function setBody(dataRange, rowFormatting, data=null) {
  if (data) dataRange.values = data;
  setDefaultRowFormatting(dataRange);
  setRowFormatting(dataRange, rowFormatting);
}

function setBorderFormatting(dataRange, rowFormatting, i) {
  let borders = rowFormatting[i]?.customFormatting?.borders;
  if (borders === undefined || borders === null) return;
  let lastIndex = rowFormatting.length-1;
  let row = dataRange.getRow(i);
  if (borders?.all?.style) borders.all.style = borders.all.style.toLowerCase();
  if (borders?.bottom?.style) borders.bottom.style = borders.bottom.style.toLowerCase();
  if (borders?.top?.style) borders.top.style = borders.top.style.toLowerCase();
  if (borders?.left?.style) borders.left.style = borders.left.style.toLowerCase();
  if (borders?.right?.style) borders.right.style = borders.right.style.toLowerCase();

  const allBordersExcept = [Excel.BorderIndex.edgeTop, Excel.BorderIndex.edgeRight, Excel.BorderIndex.edgeBottom, Excel.BorderIndex.edgeLeft, Excel.BorderIndex.insideHorizontal, Excel.BorderIndex.insideVertical];
  if (borders?.all) {
    for (const border of allBordersExcept) {
      if (i === 0 && border === Excel.BorderIndex.edgeTop){
        row.format.borders.getItem(Excel.BorderIndex.edgeTop).style = rowFormatting[i]?.customFormatting?.borders?.top?.style || rowFormatting[i]?.customFormatting?.borders?.all?.style;
        continue;
      }
      if (
          (
              rowFormatting[i - 1]?.customFormatting?.borders?.all?.style !== 'none' ||
              (rowFormatting[i - 1]?.customFormatting?.borders?.bottom?.style && rowFormatting[i - 1]?.customFormatting?.borders?.bottom?.style !== 'none')
          ) &&
          border === Excel.BorderIndex.edgeTop
      ) {
        console.log(border);
        console.log(i);
        continue;
      }

      if (
          (
              rowFormatting[i + 1]?.customFormatting?.borders?.all?.style !== 'none' ||
              (rowFormatting[i - 1]?.customFormatting?.borders?.top?.style && rowFormatting[i - 1]?.customFormatting?.top?.bottom?.style !== 'none')
          )
          && border === Excel.BorderIndex.edgeBottom) {
        console.log(border);
        console.log(i);
        continue;
      }
      if (i === lastIndex && border === Excel.BorderIndex.edgeBottom) continue;

      if (borders?.all?.style)
        row.format.borders.getItem(border).style = borders.all.style;
      if (borders?.all?.color)
        row.format.borders.getItem(border).color = borders.all.color;
    }

  }
}

function setRowFormatting(dataRange, rowFormatting) {
  for (let i = 0;i<rowFormatting.length;i++) {
    let rowRange = dataRange.getRow(i);
    setFormatting(rowRange, rowFormatting[i]);
    setBorderFormatting(dataRange, rowFormatting, i);
  }
}


export const importInstantReportIntoExcel = async (report) => {
  if (report.length === 0) return;
  console.log(report);
  try {
    await Excel.run(async (context) => {
      OfficeExtension.config.debugInfo = true;
      context.workbook.application.suspendApiCalculationUntilNextSync();
      context.workbook.application.suspendScreenUpdatingUntilNextSync();
      context.runtime.enableEvents = false;
      OfficeExtension.config.debugInfo = true;

      const sheet = context.workbook.worksheets.getActiveWorksheet();
      // const styles = JSON.parse( await OfficeRuntime.storage.getItem('instant-report-style'));
      // const styles = styles;
      const epKey = Object.keys(report[0]).includes('EP') ? 'EP' : 'ep';
      const { data, columnFormatting, rowFormatting } = getData(report, epKey);
      let dataRange, headerRange = null;
      // console.log(data);
      // console.log(columnFormatting);
      // console.log(rowFormatting);
      const { headerData, headerFormatting } = getHeaderData(report, epKey);
      const {metaDataFormatting, metaDataValues} = getMetaData(report, epKey);
      if (metaDataValues.length !== metaDataFormatting.length) {
        console.log(`Possible error at getMetaData()! Different length of arr`);
        return {error: "Inconsistent metadata!", data: metaDataValues, format: metaDataFormatting};
      }
      // if ((data.length !== rowFormatting.length && data.length !== 0)  || data[0]?.length !== columnFormatting.length) {
      if (data.length !== rowFormatting.length || data[0]?.length !== columnFormatting.length) {
        clearSheet(sheet);
        setMetaData(sheet, metaDataValues, metaDataFormatting);
        if (headerData && headerFormatting) {
          headerRange = sheet.getUsedRange().getLastRow().getCell(0, 0).getResizedRange(1, 0).getLastRow().getResizedRange(0, headerData.length-1);
          setHeader(headerRange, headerData, headerFormatting);
        }
        console.log(`Possible error at getData()! Different length of arr`);
        return {error: "Inconsistent data!", data: data, columns: columnFormatting, rows: rowFormatting};
      }

      clearSheet(sheet);
      setMetaData(sheet, metaDataValues, metaDataFormatting);

      if (headerData && headerFormatting) {
        headerRange = sheet.getUsedRange().getLastRow().getCell(0, 0).getResizedRange(1, 0).getLastRow().getResizedRange(0, headerData.length-1);
        setHeader(headerRange, headerData, headerFormatting);
      }
      dataRange = sheet.getUsedRange().getLastRow().getCell(0, 0).getResizedRange(1, 0).getLastRow().getResizedRange(data.length-1, data[0].length-1);

      if (data) {
        setBody(dataRange, rowFormatting, data);
        // const batchSize = 2000;
        // for (let i = 0; i < data.length; i += batchSize) {
        //   const batchData = data.slice(i, i + batchSize);
        //   const batchRowFormatting = rowFormatting.slice(i, i + batchSize);
        //   const batchRange = sheet.getUsedRange().getLastRow().getCell(1, 0).getResizedRange(batchData.length - 1, Object.values(batchData[0]).length - 1);
        //   batchRange.values = batchData;
        //   setBody(batchRange, batchRowFormatting);
        //   if (Office.PlatformType === Office.PlatformType.OfficeOnline) {
        //     console.log('online');
        //     await context.sync();
        //   }
        // }
      }
      await context.sync();
      context.workbook.application.suspendScreenUpdatingUntilNextSync();
      context.workbook.application.suspendApiCalculationUntilNextSync();
      if (columnFormatting) {
        for (let i = 0;i<columnFormatting.length;i++){
          let column = dataRange.getColumn(i);
          setFormatting(column, columnFormatting[i]);
          console.log(columnFormatting[i]);
          if (headerRange && columnFormatting[i]?.defaultFormatting) setFormatting(headerRange.getColumn(i), columnFormatting[i]);
          if (headerRange && columnFormatting[i]?.customFormatting?.format?.fill?.color) {
            headerRange.getColumn(i).format.fill.color = darkenColor(columnFormatting[i].customFormatting?.format?.fill?.color);
            dataRange.getColumn(i).format.fill.color = columnFormatting[i].customFormatting?.format?.fill?.color;
          }

        }
      }

      if (headerRange) headerRange.untrack();
      if (rowFormatting) {
        for (let i = 0;i<rowFormatting.length;i++) {
          let rowRange = dataRange.getRow(i);
          const customFormat = rowFormatting[i]?.customFormatting;
          if (customFormat?.fillOverlap){
            if (customFormat.fillOverlap?.color) rowRange.format.fill.color = customFormat.fillOverlap.color;
            if (customFormat.fillOverlap?.pattern) rowRange.format.fill.pattern = customFormat.fillOverlap.pattern;
            if (customFormat.fillOverlap?.patternColor) rowRange.format.fill.patternColor = customFormat.fillOverlap.patternColor;
            if (customFormat.fillOverlap?.patternTintAndShade) rowRange.format.fill.patternTintAndShade = customFormat.fillOverlap.patternTintAndShade;
          }
        }

      }


      return context.sync();
    });
  } catch (e) {
    console.log(e);
    console.log(JSON.stringify(e));
    return e;
  }

}

Office.onReady(async function (info) {
  if (info.host === Office.HostType.Excel) {
    try {
      const report = await getReport();
      if (report.length === 0) return;
      await importInstantReportIntoExcel(report);
    } catch (error) {
      console.log("Error during the execution: ", error);
    }
  }
});





