import {columnWidth as columnWidths, styles} from "../config/excelConfig";

function setDefaultBorder(dataRange) {
    const defaultBorders = [Excel.BorderIndex.insideVertical, Excel.BorderIndex.insideHorizontal, Excel.BorderIndex.edgeRight, Excel.BorderIndex.edgeLeft, Excel.BorderIndex.edgeBottom, Excel.BorderIndex.edgeTop];
    for (const border of defaultBorders) {
        dataRange.format.borders.getItem(border).style = Excel.BorderLineStyle.dot;
        // dataRange.format.borders.getItem(border).color = styles['InteriorColorIRBorder'];
    }
}


function getFontBold(formatString) {
    const fontBoldRegex = /Font\s*\.\s*Bold\s*=\s*(True|False|true|false)/
    let result = formatString.match(fontBoldRegex);
    return result ? result[1].toLowerCase() === 'true' : null;
}

function getFontName(formatString) {
    const fontNameRegex = /Font\s*\.\s*Name\s*=\s*([^#]+)(?:###|$)/;
    const result = formatString.match(fontNameRegex);
    return result ? result[1].trim() : null;
}

function setFontStyle(formatString, row) {
    const fontStyleRegex = /Font\s*\.\s*FontStyle\s*=\s*([^#]+)(?:###|$)/;
    const result = formatString.match(fontStyleRegex);
    if (result){
        if (result[1].toLowerCase().replace(' ', '') === 'bolditalic') {
            row.format.font.bold = true;
            row.format.font.italic = true;
            return;
        }
        if (result[1].toLowerCase().replace(' ', '') === 'italic') {
            row.format.font.italic = true;
            return;
        }
        if (result[1].toLowerCase().replace(' ', '') === 'regular') {
            row.format.font.italic = false;
            row.format.font.bold = false;
        }
    }
}

function getFontSize(formatString) {
    const fontSizeRegex = /Font\s*\.\s*Size\s*=\s*([^#]+)(?:###|$)/;
    const result = formatString.match(fontSizeRegex);
    if (result) {
        return parseFloat(result[1].trim());
    }
    return null;
}

function getFontSubscript(formatString) {
    const subscriptRegex = /Font\s*\.\s*Subscript\s*=\s*(True|False|true|false)/;
    const result = formatString.match(subscriptRegex);

    if (result) {
        return result[1].toLowerCase() === 'true';
    }
    return null;
}

function getFontStrikethrough(formatString) {
    const strikethroughRegex = /Font\s*\.\s*Strikethrough\s*=\s*(True|False|true|false)/;
    const result = formatString.match(strikethroughRegex);

    if (result) {
        return result[1].toLowerCase() === 'true';
    }
    return null;
}

function getFontSuperscript(formatString) {
    const superscriptRegex = /Font\s*\.\s*Superscript\s*=\s*(True|False|true|false)/;
    const result = formatString.match(superscriptRegex);

    if (result) {
        return result[1].toLowerCase() === 'true';
    }
    return null;
}

function getFontItalic(formatString) {
    const italicRegex = /Font\s*\.\s*Italic\s*=\s*(True|False|true|false)/;
    const result = formatString.match(italicRegex);

    if (result) {
        return result[1].toLowerCase() === 'true';
    }
    return null;
}
function getTintAndShade(formatString) {
    const tintAndShadeRegex = /TintAndShade\s*=\s*(-?1(\.0)?|0?\.\d+|0|1)/;
    const result = formatString.match(tintAndShadeRegex);

    if (result) {
        const value = parseFloat(result[1]);
        return (value >= -1 && value <= 1) ? value : null;
    }
    return null;
}

function getFontUnderline(formatString) {
    const formattedString = formatString.replace(/\s+/g, '').toLowerCase();

    if (formattedString.includes("font.underline=xlunderlinestyledouble")) {
        return Excel.RangeUnderlineStyle.double;
    }
    if (formattedString.includes("font.underline=xlunderlinestyledoubleaccounting")) {
        return Excel.RangeUnderlineStyle.doubleAccountant;
    }
    if (formattedString.includes("font.underline=xlunderlinestylenone")) {
        return Excel.RangeUnderlineStyle.none;
    }
    if (formattedString.includes("font.underline=xlunderlinestylesingle")) {
        return Excel.RangeUnderlineStyle.single;
    }
    if (formattedString.includes("font.underline=xlunderlinestylesingleaccounting")) {
        return Excel.RangeUnderlineStyle.singleAccountant;
    }

    return null;
}

function getFontColor(formatString) {
    const rgbRegex = /Font\s*\.\s*Color\s*=\s*RGB\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)/i;
    const result = formatString.match(rgbRegex);
    return rgbToHex(result);
}


function rgbToHex(result) {
    if (result) {
        const red = result.r;
        const green = result.g;
        const blue = result.b;

        return `#${((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1).toUpperCase()}`;
    }
    return null;
}



function parseExcelBorderFromVBA(vbaBorderName) {
    switch (vbaBorderName){
        case 'xlLineStyleNone':
            return Excel.BorderLineStyle.none;
        case 'xlContinuous':
            return Excel.BorderLineStyle.continuous;
        case 'xlDash':
            return Excel.BorderLineStyle.dash;
        case 'xlDashDot':
            return Excel.BorderLineStyle.dashDot;
        case 'xlDashDotDot':
            return Excel.BorderLineStyle.dashDotDot;
        case 'xlDot':
            return Excel.BorderLineStyle.dot;
        case 'xlDouble':
            return Excel.BorderLineStyle.double;
        case 'xlSlantDashDot':
            return Excel.BorderLineStyle.slantDashDot;
        default:
            console.log("Couldn't find border!")
            return Excel.BorderLineStyle.none;
    }
}


function getBorderLineStyle(formatString) {
    const generalBorderStyleRegex = /Borders(?:\((xlEdgeTop|xlEdgeBottom|xlEdgeLeft|xlEdgeRight|xlDiagonalDown|xlDiagonalUp|xlInsideHorizontal|xlInsideVertical)\))?\s*\.LineStyle\s*=\s*(xlLineStyleNone|xlLineStyleContinuous|xlLineStyleDash|xlLineStyleDashDot|xlLineStyleDashDotDot|xlLineStyleDot|xlLineStyleDouble|xlLineStyleSlantDashDot)/i;
    const generalResult = formatString.match(generalBorderStyleRegex);
    if (formatString.match("#LINE#")){
        return Excel.BorderLineStyle.none;
    }
    if (generalResult) {
        if (generalResult[2] !== undefined){
            return parseExcelBorderFromVBA(generalResult[2]);
        }
        return Excel.BorderLineStyle[generalResult[2].replace(/.*=\s*/, '').toLowerCase()];
    }

    return null;
}

function getBorderColor(formatString) {
    const borderColorRegex = /Borders\s*\.\s*Color\s*=\s*RGB\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)/i;
    const result = formatString.match(borderColorRegex);
    return result ? `#${((1 << 24) + (parseInt(result[1], 10) << 16) + (parseInt(result[2], 10) << 8) + parseInt(result[3], 10)).toString(16).slice(1).toUpperCase()}` : null;
}

function getBorderWeight(formatString) {
    const borderWeightRegex = /Borders\s*\.\s*Weight\s*=\s*(xlHairline|xlThin|xlMedium|xlThick)(?:###|$)/i;
    const result = formatString.match(borderWeightRegex);
    if (result)
        switch (result[1].replace(" ", "").toLowerCase()){
            case "xlhairline":
                return Excel.BorderWeight.hairline;
            case "xlthin":
                return Excel.BorderWeight.thin;
            case "xlmedium":
                return Excel.BorderWeight.medium;
            case "xlthick":
                return Excel.BorderWeight.thick;
            default:
                return null;
        }
    return null;
}

function setBorder(item, dataRange, epColumns, epIndex, lastIndex) {
    const excelBorder = getBorderLineStyle(item.format);
    const excelBorderColor = getBorderColor(item.format);
    const excelBorderWeight = getBorderWeight(item.format);
    if (excelBorder){
        let defaultBorders = [Excel.BorderIndex.insideVertical, Excel.BorderIndex.insideHorizontal, Excel.BorderIndex.edgeRight, Excel.BorderIndex.edgeLeft];
        for (const border of defaultBorders) {
            if (item.index === 0){
                dataRange.getRow(item.index).format.borders.getItem(Excel.BorderIndex.edgeTop).style = excelBorder;
            }
            //top border check
            if (epColumns[epIndex-1]) {
                if (epColumns[epIndex-1].index+1 === item.index) {
                    let topBorder = getBorderLineStyle(epColumns[epIndex-1].format); //todo
                    if (topBorder) dataRange.getRow(item.index).format.borders.getItem(Excel.BorderIndex.edgeTop).style = excelBorder;
                }
            }

            //bottom border check
            if (epColumns[epIndex+1]) {
                if (epColumns[epIndex+1].index-1 === item.index) {
                    let bottomBorder = getBorderLineStyle(epColumns[epIndex+1].format);
                    if (bottomBorder) dataRange.getRow(item.index).format.borders.getItem(Excel.BorderIndex.edgeBottom).style = excelBorder;
                }
            }
            if (item.index === lastIndex){
                dataRange.getRow(item.index).format.borders.getItem(Excel.BorderIndex.edgeBottom).style = excelBorder;
            }
            dataRange.getRow(item.index).format.borders.getItem(border).style = excelBorder;
        }
        // return excelBorder;
    }
    if (excelBorderColor){
        let defaultBorders = [Excel.BorderIndex.insideVertical, Excel.BorderIndex.insideHorizontal, Excel.BorderIndex.edgeRight, Excel.BorderIndex.edgeLeft, Excel.BorderIndex.edgeTop];
        for (const border of defaultBorders) {
            dataRange.getRow(item.index).format.borders.getItem(border).color = excelBorderColor;
        }
    }
    if (excelBorderWeight) {
        let defaultBorders = [Excel.BorderIndex.insideVertical, Excel.BorderIndex.insideHorizontal, Excel.BorderIndex.edgeRight, Excel.BorderIndex.edgeLeft, Excel.BorderIndex.edgeTop];
        for (const border of defaultBorders) {
            dataRange.getRow(item.index).format.borders.getItem(border).weight = excelBorderWeight;
        }

    }
}

function clearSheet(sheet) {
    sheet.getRange().clear(Excel.ClearApplyTo.all);
    sheet.getRange().format.rowHeight = 15;
    sheet.getRange().format.columnWidth = 47.83;
    sheet.freezePanes.unfreeze();
    sheet.showGridlines = false;
    sheet.getRange("A1").format.columnWidth = columnWidths[3];
}


function getData(report, epKey) {
    const skipEpColumns = ["TITLE", "DESC", "INFO", "PARA", "COLSIZE", "FORMAT", "HEADER", "HEADERNOFILTER"];
    let columnFormatting = [];
    let rowFormatting = [];

    const data = report
        .filter(item => {
            const epValue = item[epKey];
            if (epValue === null || epValue === "" || epValue === " ") rowFormatting.push(epValue);
            let parsedEpValue;
            try {
                parsedEpValue = JSON.parse(epValue);
            } catch (e) {
                return true;
            }
            if (parsedEpValue === null) return true;

            if (skipEpColumns.every(item => item !== parsedEpValue?.defaultFormatting))
                rowFormatting.push(parsedEpValue);


            if (parsedEpValue && parsedEpValue.defaultFormatting?.toString().toUpperCase() === "FORMAT") {
                try {
                    const keys = Object.keys(item);
                    for(let key of keys){
                        let parsedColumnFormat = JSON.parse(item[key]);
                        if (parsedColumnFormat.defaultFormatting === "FORMAT") continue;
                        columnFormatting.push(parsedColumnFormat);
                    }
                } catch (e) {
                    console.log(`Couldn't parse ${item}`);
                }
            }

            return !skipEpColumns.includes(parsedEpValue.defaultFormatting);
        })
        .map(({ [epKey]: _, ...rest }) => Object.values(rest));

    return {
        data,
        columnFormatting,
        rowFormatting
    };
}

function getMetaData(report, epKey) {
    const validFormats = ["TITLE", "DESC", "INFO", "PARA"];
    const metaDataFormatting = [];
    const metaDataValues = [];
    report.filter(item => {
        try {
            const parsedItem = JSON.parse(item[epKey]);
            if (validFormats.includes(parsedItem?.defaultFormatting?.toUpperCase())){
                metaDataFormatting.push(parsedItem);
                const itemWithoutEpKey = Object.keys(item).reduce((acc, key) => {
                    if (key !== epKey) acc[key] = item[key];
                    return Object.values(acc);
                }, {});

                metaDataValues.push(itemWithoutEpKey);
                return true;
            }
            return false;
        } catch (e) {
            return false;
        }
    });
    return {metaDataFormatting: metaDataFormatting, metaDataValues: metaDataValues};
}

function getHeaderData(report, epKey) {
    const result = report.find(item => {
        try {
            const parsedItem = JSON.parse(item[epKey]);
            return parsedItem?.defaultFormatting.toUpperCase() === 'HEADER' || parsedItem?.defaultFormatting.toUpperCase() === 'HEADERNOFILTER';
        } catch (e) {
            return false;
        }
    });

    if (result !== undefined) {
        const headerData = Object.keys(result)
            .filter(key => key !== epKey)
            .map(key => result[key]);

        const headerFormatting = JSON.parse(result[epKey]);

        return {
            headerData,
            headerFormatting
        };
    }

    return {
        headerData: [],
        headerFormatting: null
    };
}

function parseRgbString(rgbString) {
    const [r, g, b] = rgbString
        .replace(/[^\d,]/g, '')
        .split(',')
        .map(Number);
    return { r, g, b };
}

function hexToRgb(hex) {
    hex = hex.replace('#', '');

    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return { r, g, b };
}



function darkenColor(hexColor) {
    let rgbColor = hexToRgb(hexColor);

    rgbColor.r = Math.round(Math.pow(Math.pow(rgbColor.r, 2.2) * 0.85, 1 / 2.2));
    rgbColor.g = Math.round(Math.pow(Math.pow(rgbColor.g, 2.2) * 0.85, 1 / 2.2));
    rgbColor.b = Math.round(Math.pow(Math.pow(rgbColor.b, 2.2) * 0.85, 1 / 2.2));
    return rgbToHex(rgbColor);
}




export {darkenColor, getHeaderData, rgbToHex, getBorderWeight, getData, getMetaData, clearSheet, setBorder, setDefaultBorder, parseExcelBorderFromVBA, getFontColor, getFontBold, getFontItalic, getFontName, getFontSize, setFontStyle, getFontStrikethrough, getFontSubscript, getTintAndShade, getFontUnderline, getFontSuperscript}