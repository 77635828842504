import axios from "axios";
import { paraJoinedChar } from "./separators";
// import {getDefaultStyle} from "../officeDocuments/excelHelper";

// export const host = "https://ifis.upointdms.dev/api";
// export const host = "http://localhost:8080/api";
export const host = "https://add-in-server.blubina.de/api";

// Client
// Get Logo, Product Name, Title, Contact     => .../api/homepage
export const urlMainClientProp = `${host}/homepage`;

// Report
// Get List                                   => .../api/instant-reports
// Get Report                                 => .../api/instant-reports/:report
// Get Report Parameters                      => .../api/instant-reports/:report/parameters
export const urlIntRptListGet = `${host}/instant-reports`;

// Table
// Get List                                   => .../api/tables
// Get Table                                  => .../api/tables/:report
export const urlTblListGet = `${host}/tables`;

// =========================== Clients ===========================
export const fetchMainClientData = async (signal) => {
  const { data } = await axios.get(urlMainClientProp, await getAuthenticationHeader(), { signal });
  return data;
};

// =========================== Instant Report ===========================
export const fetchIntRptListData = async () => {
  const { data } = await axios.get(urlIntRptListGet, await getAuthenticationHeader());
  const sortedData = data
    .map(({ HIDE, PARACOUNT, PARADEFAULTS, PARALABELS, PARANAMES, PARASIZES, PARATYPES, SPDESC, USERACCESSSP }) => ({
      name: USERACCESSSP,
      displayName: SPDESC,
      isHidden: HIDE,
    }))
    .sort((a, b) => (a.displayName > b.displayName ? 1 : b.displayName > a.displayName ? -1 : 0));
  console.log(sortedData);
  return sortedData;
};

export const fetchIntRptParaData = async (reportName) => {
  console.log(reportName);
  let checkboxParaList;
  let nonCheckboxParaList;
  let sortedData = [];

  const { data } = await axios.get(`${urlIntRptListGet}/${reportName}/parameters`, await getAuthenticationHeader());
  console.log(data);
  checkboxParaList = data.filter(({ valueType }) => valueType === "checkbox");
  nonCheckboxParaList = data.filter(({ valueType }) => valueType !== "checkbox");

  if (checkboxParaList.length > 0) {
    sortedData = [...nonCheckboxParaList, ...checkboxParaList];
  } else {
    sortedData = nonCheckboxParaList;
  }

  return sortedData;
};

export const loadIntRptData = async (reportName, paraArr) => {
  if (paraArr.length === 0) {
    const { data } = await axios.get(`${urlIntRptListGet}/${reportName}`, await getAuthenticationHeader());
    return data;
  } else {
    const stringifiedParameters = paraArr.join(paraJoinedChar);

    const { data } = await axios.get(
      `${urlIntRptListGet}/${reportName}?${stringifiedParameters}`,
      await getAuthenticationHeader()
    );
    return data;
  }
};

export const getReportStyle = async () => {
  try {
    const { data } = await axios.get(`${urlIntRptListGet}/properties/styles`, await getAuthenticationHeader());
    return data;
  } catch (err) {
    console.log("Err: ", err);
    throw err;
  }
};

// =========================== Table Window ===========================
export const fetchTableListData = async () => {
  const { data } = await axios.get(urlTblListGet, await getAuthenticationHeader());
  const sortedData = data
    .filter(({ tableName }) => tableName.length !== 0)
    .map(({ tableID, tableName, desc, HIDE }) => ({
      id: tableID,
      name: tableName,
      displayName: desc,
      isHidden: HIDE,
    }))
    .sort((a, b) => (a.displayName > b.displayName ? 1 : b.displayName > a.displayName ? -1 : 0));

  return sortedData;
};

export const loadTableStructureData = async (tableName) => {
  const { data } = await axios.get(`${urlTblListGet}/${tableName}/header`, await getAuthenticationHeader());
  return data;
};
export const loadTableData = async (tableName) => {
  const { data } = await axios.get(`${urlTblListGet}/${tableName}/data`, await getAuthenticationHeader());
  return data;
};

// =========================== Auth ===========================
export const getAuthToken = async () => {
  return await Office.auth
    .getAccessToken({
      allowSignInPrompt: true,
      allowConsentPrompt: true,
      forMSGraphAccess: true,
    })
    .catch((err) => {
      console.log("Err: ", err);
      throw err;
    });
};

export const getAccessToken = async () => {
  try {
    let token = await OfficeRuntime.storage.getItem("accessToken");

    if (!token) {
      const newToken = await getAuthToken();
      await OfficeRuntime.storage.setItem("accessToken", newToken);
      await OfficeRuntime.storage.setItem('instant-report-style', JSON.stringify(await getReportStyle()));
      console.log(await getReportStyle());
      return newToken;
    }

    let encodedPayload = token.split(".")[1];
    let tokenExpiration = JSON.parse(atob(encodedPayload))["exp"];
    let epochTimeNow = Math.floor(Date.now() / 1000);

    //call for a new token after expiration time
    if (tokenExpiration - epochTimeNow <= 0) {
      const newToken = await getAuthToken();
      await OfficeRuntime.storage.setItem("accessToken", newToken);
      await OfficeRuntime.storage.setItem('instant-report-style', JSON.stringify(await getReportStyle()));
      return newToken;
    }

    return token;
  } catch (err) {
    console.log("Err: ", err);
    throw err;
  }
};

export const login = async () => {
  try {
    const accessToken = await getAccessToken();
    return accessToken !== null;
  } catch (err) {
    console.log("Err: ", err);
    return false;
  }
};

export const getAuthenticationHeader = async () => {
  try {
    const accessToken = await getAccessToken();

    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  } catch (err) {
    console.log("Err: ", err);
    throw err;
  }
};
